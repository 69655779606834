//  Pages styles

/*
MEDIA QUERY BREAKPOINTS

iPHONE 5
@media (min-width: 320px) 
  and (max-width: 568px)
  and (orientation: portrait) {
}

@media (min-width: 320px) 
  and (max-width: 568px)
  and (orientation: landscape) {
}

iPHONE 6
@media (min-width: 375px) 
  and (max--width: 667px) 
  and (orientation: portrait) { 
}

@media (min-width: 375px) 
  and (max-width: 667px) 
  and (orientation: landscape) { 
}

iPAD
@media (min-width: 768px) 
  and (max-width: 1024px) 
  and (orientation: portrait)  {
}

@media (min-width: 768px) 
  and (max-width: 1024px) 
  and (orientation: landscape) {
}

BIGGER

@media (min-width: 1024px) { 
}

*/

.jumbtoron-top-border{
	height: 5px;
	padding-top: 10px;
	padding-bottom: 25px;
	background-position: center;
	background-repeat: repeat-x;
	display: none;

	@media (min-width: 320px) 
	  and (max-width: 568px){

	}

	@media (min-width: 375px) 
	  and (max-width: 667px)  { 

  	}

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
	  	display:block;
	}

	@media (min-width: 1024px) 
		and (max-width: 1600px)  { 
	  	display:block;
	}

	@media (min-width: 1600px)  { 
	  	display:block;
	}

}

.jumbotron-home {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: $home-page-main-image-height;
	margin-bottom:$home-page-banner-margin-bottom;

	@media (min-width: 568px) {
	  	height: $home-page-main-image-height-xs-landscape;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
	  	height: $home-page-main-image-height-sm;	  	
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
	  	height: $home-page-main-image-height-md;	  	
  	}

	@media (min-width: 1025px)  {  
	  	height: $home-page-main-image-height-lg;
	}

}

.jumbotron-generic {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: $generic-page-main-image-height;
	margin-bottom:$generic-page-banner-margin-bottom;

	@media (min-width: 568px) {
	  	height: $generic-page-main-image-height-xs-landscape;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
	  	height: $generic-page-main-image-height-sm;	  	
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
	  	height: $generic-page-main-image-height-md;	  	
  	}

	@media (min-width: 1025px)  {  
	  	height: $generic-page-main-image-height-lg;
	}
}

.jumbotron-centre-home{
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: $centre-page-main-image-height;
	margin-bottom:$centre-page-banner-margin-bottom;

	@media (min-width: 568px) {
	  	height: $centre-page-main-image-height-xs-landscape;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
	  	height: $centre-page-main-image-height-sm;	  	
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
	  	height: $centre-page-main-image-height-md;	  	
  	}

	@media (min-width: 1025px)  { 
	  	height: $centre-page-main-image-height-lg;
	}
}

// Section seperators
.section-proud{
	font-size: $section-proud-font-size;
	color: $section-proud-colour;
	font-weight: 600;
}

.section-title{
	font-size: $section-title-font-size;
	color: $section-title-colour;
	font-weight: 600;
}

.section-description{
	font-size: $section-description-font-size;
	color: $section-description-colour;
	font-weight: 300;
}

.section-description-alt{
	font-size: $section-description-font-size;
	color: $section-description-alt-colour;
	font-weight: 300;
}

.section-seperator{
	margin-bottom: 30px;
}

.section-small-seperator{
	margin-bottom: 10px;
}

.section-paragraph{
	margin-bottom: 55px;
}

//
.anchor-hidden-hyperlink a:focus, a:hover{
	text-decoration: none;
}

//  content boxes
.content-image{
	top:0;
	z-index: 0;

	@media (min-width: 768px) { 
  		left:0;
		position:absolute;	  	
  	}

	@media (min-width: 1024px) {  
  		left:0;
		position:absolute;
	}
}

.content-image-overlap{
  	-webkit-transform: translateY(-10%);
  	-ms-transform: translateY(-10%);
  	transform: translateY(-10%);	
	z-index: 0;
}

.neutral-box {
	background-color: $page-background-colour;
	@include grid-content-box-mixin(350px);
}

.neutral-box-google-map {
	background-color: $page-background-colour;
	@include grid-content-box-mixin(1010px);
}

.sand-box-google-map{
	background-color: $brand-sand;
	@include grid-content-box-mixin(970px);
}

.green-box {
	background-color: $brand-green;
	@include grid-content-box-mixin(350px);
}

.green-box-home{
	background-color: $brand-green;
	@include grid-content-box-mixin();
	@include home-content-box-heights();
}

.sand-box-home{
	background-color: $brand-sand;
	@include grid-content-box-mixin();
	@include home-content-box-heights();
	padding-left:0px;
	padding-right:0px;
}

.blue-box-home {
	background-color: $brand-blue;
	@include grid-content-box-mixin();
	@include home-content-box-heights();
}

.green-box-contactus {
	background-color: $brand-green;
	@include grid-content-box-mixin(700px);
}

.green-box-fees {
	background-color: $brand-green;
	@include grid-content-box-mixin();
}

.sand-box {
	background-color: $brand-sand;
	@include grid-content-box-mixin(350px);
}

.blue-box {
	background-color: $brand-blue;
	@include grid-content-box-mixin(350px);
}

.blue-box-choose-centre {
	background-color: $brand-blue;
	@include grid-content-box-mixin();
	padding-bottom:15px;
}

.blue-box-values {
	background-color: $brand-blue;
	@include grid-content-box-mixin();
	padding-top:30px;
	padding-bottom:30px;
	padding-left:10px;
  	padding-right:10px;
}


// group box styles (Values and curriculum)
.group-wrapper-sand{
	@include group-wrapper-default(10px, 0px, 25px, 0px, $brand-sand);
	padding-bottom:40px;
}

.group-title{
	@include group-title-default;
}

.group-subtitle{
	@include group-subtitle-default;
}

.group-text{
	@include group-text-default;
}

//  contact us 
.contactus-wrapper-sand{
	@include group-wrapper-default(10px, 0px, 25px, 0px, $brand-sand);
	padding-bottom: 50px;
}

.contact-input{
	margin-bottom: 5px;
	height: 60px;
	border-radius: 0px;
	border: none;
	background-color: $white;	
}

.contactus-title{
	@include group-title-default($brand-green);
}

.contactus-subtitle{
	@include group-subtitle-default($brand-green);
}

.contactus-text{
	@include group-text-default();
}

.contactus-alpha-phone{
	text-transform: uppercase;
	font-weight: 800;
	font-size: 1.1em;
	font-family: $opensans;
	color: $white;
	text-align: center;
}

.contactus-numeric-phone{
	font-weight: 400;
	font-size: 1.1em;
	font-family: $opensans;
	color: $white;
	text-align: center;
}

.contactus-info-email{
	font-weight: 800;
	font-size: 0.9em;
	font-family: $opensans;
	color: $white;
	text-align: center;
}

//  careers
.position-contact{
	color: $brand-blue;
	font-size: 1em;

	.highlight{
		font-weight: 800;
		font-size: 1.25em;
	}
}

// content page large image header
.content-page-image-header{
	padding-top:15px;
}

// ########################################## Main Home ############################################


// ########################################## Centre Home ##########################################
.centre-home-box {
	background-color: $brand-centre-home;
	@include grid-content-box-mixin();
	padding-left:15px;
	padding-right:15px;
	padding-bottom:35px;
	text-decoration: none;
}

.centre-home-logo{
	padding-top:0;
	padding-bottom: 20px;
	margin-top: -10%;
}

.centre-home-strapline{
	padding-bottom:35px;	
}

.centre-home-title-text{
	font-size: 2.4em;
	color: $brand-purple;
	font-weight:600;	

	@media (min-width: 375px) and (max-width: 667px) {
		font-size: 1.75em;		
		padding:10px;
	}

	@media (min-width: 668px) and (max-width: 768px) { 
		font-size: 2.0em;		
		padding:10px;  	
		margin-right: -20%;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		font-size: 2.25em;		
		padding:10px;  	
		margin-right: -30%;
  	}

	@media (min-width: 1025px)  {  
		font-size: 2.4em;		
		padding:10px;
		margin-right: -25%;
	}	
}

.centre-home-summary{
	color:$brand-blue;
	font-size: 1.25em;
	text-align: left;
	padding-bottom:20px;
}

.centre-home-text{
	text-align: left;
	color:$brand-text-colour;
	font-size: 1.0em;	
	padding-bottom:20px;
}

.centre-contact-us-intro{
	color:$brand-text-colour;
	font-size: 1.1em;	
	padding-bottom:10px;
	
	z-index: 500;	

	@media (min-width: 568px) {
	  	width:50%;
		margin-left:25%;
		padding-top: 20px; 
	}

	@media (min-width: 667px) and (max-width: 768px) { 
	  	width:50%;
		margin-left:25%;	
		padding-top: 50px;  	
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
	  	width:50%;
		margin-left:25%;
		padding-top: 100px;	  	
  	}

	@media (min-width: 1025px)  {  
	  	width:55%;
		margin-left:25%;
		padding-top: 100px;
	}   
}

.centre-contact-us-phone, .centre-contact-us-email{
	color:$brand-blue;
	font-weight: 600;
	font-size: 1.3em;	
	padding-bottom:10px;
	z-index: 500;	

    a {
		color: $brand-blue;
		text-decoration: none;

	&:hover,
		&:focus {
			color: $brand-blue;
			text-decoration: none;
		}
    }



 
}

.centre-contact-us-message{
	color: $white;
}

.centre-contact-us-email:before { content: 'E:\00a0'; color: $brand-blue; font-weight: 300}
.centre-contact-us-phone:before { content: 'P:\00a0'; color: $brand-blue; font-weight: 300 }

.centre-home-ero-report-box{
	background-color: $brand-blue;
	@include grid-content-box-mixin(300px);
	@include centre-side-bar-box(0px);
}

.centre-home-view-photos-box{
	background-color: $brand-green;
	@include grid-content-box-mixin(300px);	
	@include centre-side-bar-box(0px);
}

.centre-open-hours-box{
	background-color: $brand-green;
	@include grid-content-box-mixin(250px);
	@include centre-side-bar-box(0px);
}

.centre-open-hours-title{
	color:$white;
	text-align: center;
	font-size: 1.1em;	
	font-weight:400;
}

.centre-open-hours-text{
	color:$white;
	text-align: center;
	font-size: 0.8em;	
	font-weight:300;
	padding-bottom:10px;
	padding-top: 10px;
}

.centre-age-ranges-box{
	background-color: $brand-blue;
	@include grid-content-box-mixin(250px);
	@include centre-side-bar-box(0px);
}

.centre-age-ranges-title{
	color:$white;
	text-align: center;
	font-size: 1.1em;	
	font-weight:400;
	padding-top: 20px;
}

.centre-age-range-value{
	color: $brand-orange;
	font-weight: 800;
	font-size: 4.0em;
	line-height: 1;
}

.centre-age-range-unit{
	color: $white;
	font-weight: 300;
	font-size: 0.8em;
	line-height: 1;
}

.centre-google-map-box{
	background-color: $brand-centre-home;
	@include grid-content-box-mixin();
}

.centre-directions-box{
	background-color: $brand-blue;
	@include grid-content-box-mixin();
	@include centre-side-bar-box(0px);	
}

.centre-directions-text{
	color:$white;
	text-align: center;
	font-size: 0.9em;	
	font-weight:300;
	padding-top: 30px;
	padding-bottom: 30px;
	width:80%;
	margin-left:10%;	
}


// ########################################## Centre Gallery ########################################
.photo-gallery-thumbnail{
	padding-bottom: 25px;
}

// ########################################## Centre News ###########################################
.centre-news-box{
	background-color: $brand-green;
	@include grid-content-box-mixin();	
	padding-left:10px;
	padding-right:10px;
	padding-bottom:20px;
}

@mixin news_item{
	width:90%;
	margin-left:5%;
	margin-right:5%;
}

.centre-recent-items-list,  .centre-recent-items-list-item{ 
	list-style-type: none;
    list-style-position:inside;
    margin:0;
    padding:0; }

.centre-recent-items-list{

}

.centre-recent-items-list-item{
	font-size:0.8em;
	color:$brand-sand;
}

.news_item_title{
	@include news_item();
	padding-top:20px;
	color: $brand-green;
	font-weight:800;
	font-size: 3.0em;
}

.news_item_subtitle{
	@include news_item();
	padding-top:15px;
	color: $brand-green;
	font-weight:500;
	font-size: 1.75em;	
}

.news_item_text{
	@include news_item();
	padding-top:10px;
	color: $brand-text-colour;
}

.centre-no-news-found{
	color: $white;
	font-size: 1.25em;
}

// ########################################## Centre Fees ##########################################
.fees-title{
	color:$white;
	text-align: center;
	font-size: 1.3em;	
	font-weight:400;
	padding-top: 30px;	
	padding-bottom: 50px;	
	width:80%;
	margin-left:10%;		
}

.fees-group-title{
	@include group-title-default($brand-green);
}

.fees-group-subtitle{
	@include group-subtitle-default($brand-green);
}

.centre-fees-box{
	background-color: $brand-blue;
	@include grid-content-box-mixin();
}

//  centre transitions
.centre-transitions-box{
	background-color: $brand-blue;
	@include grid-content-box-mixin();
}

// ########################################## Centre Daily Routine ##########################################
.centre-routine-activity-start-time{
	color:$brand-blue;
	text-align: center;
	font-size: 3.0em;	
	font-weight:800;
	padding-top: 15px;		
	padding-bottom: 15px;	
}

.centre-routine-activity-box{
	background-color: $brand-sand;
	@include grid-content-box-mixin();	
	margin-top:10px;
	margin-bottom:10px;
	margin-left: 0px;
	margin-right: 0px;
}

.centre-routine-activity-subtitle{
	@include group-subtitle-default($brand-blue);
	text-align: center;
	padding-top: 25px;		
	padding-bottom: 15px;
}

.centre-routine-activity-description{
	@include group-text-default();
	padding-left:20px;
}

// ########################################## Centre Contact Us ##########################################
.centre-contact-us-box{
	background-color: $brand-blue;
	@include grid-content-box-mixin();
	padding-top: 35px;
	padding-bottom: 35px;
}

.centre-contactus-subtitle{
	color:$white;
	text-align: center;
	font-size: 1.05em;	
	font-weight:600;
	padding-left:10px;
	padding-right:10px;
}

.contact-us-wrapper-sand{
	@include group-wrapper-default(0px, 0px, 0px, 0px, $brand-sand);
	//height:200px;
	padding-bottom:5px;	
}	

.form-control#centre-contactus-name, #centre-contactus-phone, #centre-contactus-email, #centre-contactus-message{
	width:95%;
	display:inline;
}

// ########################################## Centre Team ##########################################
.centre-team-box{
	background-color: $brand-green;
	@include grid-content-box-mixin();
	margin-bottom:15px;
}

.centre-team-title{
	color:$white;
	text-align: center;
	font-size: 1.0em;	
	font-weight:600;
	padding-top: 15px;	
	padding-bottom: 15px;	

 	@media (min-width:40em) { 
		font-size: 1.15em;
		padding-top: 10px;		
	}

 	@media (min-width:60em) { 
		font-size: 1.25em;
		padding-top: 10px;		
	}

 	@media (min-width:80em) { 
		font-size: 1.5em;
		padding-top: 15px;		
	}
}

.centre-team-subtitle{
	color:$white;
	text-align: center;
	font-size: 0.7em;	
	font-weight:400;
	padding-bottom: 20px;	
	width:90%;
	margin-left:5%;

 	@media (min-width:40em) { 
		font-size: 0.75em;
	}

 	@media (min-width:60em) { 
		font-size: 0.9em;
	}

 	@media (min-width:80em) { 
		font-size: 1.0em;
	}
}

.centre-team-text{
	@include group-text-default();
}

@mixin centre-team-member-wrapper($text-color, $background-color){
	color: $text-color;
	background: $background-color;
	padding-bottom:5px;
	margin-bottom:10px;

	h4{
		padding-top:10px;
		font-size: 1.25em;
		width:80%;
		margin-left:10%;
	}

	h5{
		padding-top:5px;
		font-size: 1.15em;
		width:80%;
		margin-left:10%;
	}

	h6{
		padding-top:10px;
		font-size: 1.0em;
		width:80%;
		margin-left:10%;
	}
}

.centre-team-member-wrapper-even{
	@include centre-team-member-wrapper($white, $brand-green);	
	@include team-member-box-heights();
}

.centre-team-member-wrapper-odd{
	@include centre-team-member-wrapper($brand-text-colour, $brand-sand);
	@include team-member-box-heights();
}

// ########################################## Management Team ######################################
.management-member-wrapper-even{
	@include centre-team-member-wrapper($white, $brand-blue);
	@include team-member-box-heights();
}

.management-member-wrapper-odd{
	@include centre-team-member-wrapper($brand-text-colour, $brand-sand);
	@include team-member-box-heights();
}

.management-team-box, .management-team-box-photo{	
	@include grid-content-box-mixin(600px);
	.centre-team-subtitle{
		width:90%;
		margin-left:5%;
	}
	padding-left:0px;
	padding-right:0px;
}

.management-team-box{
	background-color: $brand-blue;
}

.management-team-box-photo{
	background-color: $page-background-colour;
}


// ########################################## Landing Page ##########################################

.centre-tile{
	h1{
		@extend .group-title;
		color: $brand-green;
		text-align: center;
	}
}

.centre-tile-wrapper{
	background-color: $brand-green;
	padding-bottom: 35px;
	height: 560px;

	img{
		width:100%; 
		padding-bottom: 35px;
	}

	h4{
		@extend .content-box-title;
		color: $white;
		font-size: 2.0em;
		font-weight: 800;
		text-align: center;
	}

	h5{
		@extend .content-box-text;
		color: $white;
		text-align: center;

		span{
			color: $brand-orange;
		}
	}

	a {
		color: $white;
		&:hover{
			cursor: pointer;
			text-decoration: none;
			color: $white;
		}
		&:active, &:focus, &:visited{
			text-decoration: none;
			color: $white;
		}		
	}
	
}


// ########################################## Community Page ##########################################
.community-title{
	@extend .group-title;
	text-align: initial;
}

.community-text{
	@extend .group-text;
	text-align: initial;
	padding-bottom: 0px;
}

.community-box-sand{
	@extend .group-wrapper-sand;
	padding-bottom: 20px;
}