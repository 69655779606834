.content-info{
  background-color: $brand-blue;
  color: $white;
  font-family: $opensans;
  min-height: 125px;
  margin-top:100px;

  @media (max-width: 567px) {
    margin-top:20px;
  } 

  @media (min-width: 568px) {
    margin-top:20px;
  }

  @media (min-width: 667px) and (max-width: 767px) { 
    margin-top:120px; 
  }

  @media (min-width: 766px) and (max-width: 1024px) { 
    margin-top:120px;   
  }

  @media (min-width: 1025px)  {  
    margin-top:120px;
  }
}

.footer-addresses{
  padding-top: 20px;
  padding-bottom: 10px;
}

.footer-email, .footer-phone, .footer-fax{
  font-size: 1.0em;
  font-weight: 600;
}

.footer-email a, .footer-email a:hover, .footer-email a:focus, .footer-email a:visited { color: $white; }
.footer .email:before, .footer .phone:before { color: $footer-address-type-colour; }

.footer-email:before { content: 'Email:\00a0'; color: $footer-address-type-colour; }
.footer-phone:before { content: 'Phone:\00a0'; color: $footer-address-type-colour; }

.menu-footer-navigation-container{
  color: $white;
}

.centre-footer-message-container{ 
  height: 75px;
  background-image: url(../images/yellow-swipe-footer.png);
  background-repeat: no-repeat;
  background-size:contain;
  margin: 0 auto;
  margin-left:5%;
  transform: translateY(-55%); 
}

.centre-footer-message{
  color: $white;
  font-size:1.5em;
  font-weight:800;  
  float:left;
  padding-right:25px;
  margin-left:10%;
  margin-top:20px;
}

.centre-footer-button{
  @include center-footer-button-mixin($red-button-background, $white, $red-button-border);
  margin-top:15px;
  float:left;
}

.inline-column {
  display: inline-flex;
  align-items: center;
}

.footer-centre-title {
  @extend h4;
  padding-top: 10px;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.footer-centre-select {
  margin-top: 20px;
  padding-left: 10px;
  width: 150px;
}

.footer-select-centre-region{
  @extend .footer-centre-select;
}

.footer-select-centre-name{
  @extend .footer-centre-select;
}

.footer-view-centre-button {
  float: left;
  margin-left:10px;
  @media (min-width: 768px) {
    margin-left:0px;
  }
}

.footer-centre-pos{
  margin-top: 10px;
}

//  Bootstrap dropdown / select
  .btn-default{
    font-size: 1.25em;
    border-radius: 0;
    color: $brand-blue;
    background-color: $white;
    width: 100%;
    border-style: solid;
    border-width: 0px 0px 10px 0px;
    border-color: $brand-aqua;         
  }

  .dropdown-menu{
      background: $dropdown-bg-colour;
      border-radius: 0px;
      text-align: left;
      padding: 10px;
      font-size: 1.05em;
  }

  .dropdown-menu > li > a {
      color: $white;
      background-color: $dropdown-bg-colour;
      text-align: left;
      padding: 10px;

        &:focus{
          background: $dropdown-bg-colour; 
        }

        &:hover{
          background: $dropdown-bg-colour; 
        }
  }

  // border beneath all menu items except for last
  .dropdown-menu > li:not(:last-child) a { 
      border-bottom: solid $white 1px; 
  } 

  //  dropdown width
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 165px;
      @media (min-width:568px) { 
        width: 165px;
      }
      @media (min-width: 667px) and (max-width: 767px) { 
        width: 165px;
      }
      @media (min-width: 766px) and (max-width: 1024px) { 
        width: 180px;
      }
      @media (min-width: 1025px) { 
        width: 200px;
      }
  }

    @media (min-width:568px) { 
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.25em;
      }        
    }

    @media (min-width: 667px) and (max-width: 767px) { 
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.25em;
      }
    }

    @media (min-width: 766px) and (max-width: 1024px) { 
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.15em;
      }   
    }

    @media (min-width: 1025px)  {  
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.25em;
      } 
    }

.row-centered {
  text-align:center;
}

.col-centered {
  display:inline-block;
  float:none;
  margin-right:-4px;
  text-align: center;
}