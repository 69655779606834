.centre-header-container, .centre-header-container-mobile{
	position: relative; 
	left: 0; 
	top: 0;
}

.centre-header-logo{
	position: absolute; 
	left: 2%; 
	top: 2%;
}

h1#main-header-banner{
  	margin-bottom: 0;
	margin-top: -10px;
}

h1#centre-header-banner{
  	margin-bottom: -10px;
	margin-top: 10px;
}

h1#centre-header-banner-mobile{
  	margin-bottom: -10px;
	margin-top: 10px;
}