//  navbar
.nav {
	font-size: 1em;
	font-weight: 200;
}

.navbar{
	top: 0px;
	width: 100%;
	margin-bottom: 5px;
}

.navbar, .navbar-default{
	border: none;
}

.navbar-collapse{
	padding-left:0px;
	padding-right:0px;
}

.navbar-nav > li > a {
    padding-top: 5px;
}	

//  dropdown child menu
.dropdown-menu {	
	padding-top: 5px;
	padding-bottom: 5px;
}	

li#mobile-facebook-nav-item a {
  display: block;
  background-image:url('../images/facebook-nav.png');
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  text-indent: -9000px;
  margin-left: 20px;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: $brand-blue;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
	background-color: $page-background-colour;
}


@media (max-width: 567px) {
	.navbar-brand{
		margin-left:0px;
		padding-left:0px;
		padding-right:5px;
	}

	.navbar-toggle{
		margin-right:0px;
		padding-left:5px;
		padding-right:0px;
	}

	.navbar-brand>img {
	    margin-top: -5%;
	}	
}

// font sizes
@media (min-width: 568px) {


	.nav {
		font-size: 1.0em;
	}

	.nav > li > a {
		padding-left: 15px;
		padding-right:15px;
	}	

	.navbar-brand>img {
	    margin-top: -5%;
	}	
}

@media (min-width: 667px) {
 	.navbar-brand{
		height: $navbar-height-sm;
	}

	.nav > li > a {
		padding-left: 20px;
		padding-right:20px;
	}	

	.navbar > ul {
		padding: 40px;
	}

	.navbar-nav > li > a:hover  {
	    font-weight:600;
	}

	.navbar-nav > .active > a:hover {
		font-weight:600;
	}	
}

@media (min-width: 667px) and (max-width: 768px) { 
	.nav {
		font-size: 0.85em
	} 	

	.nav > li > a {
		padding-left: 2px;
		padding-right:2px;
	}	

	.navbar-nav > li > a {
	    padding-top: 10px;
	}	

 	.navbar-brand{
		height: $navbar-height-sm;
	}	

	//  hide dropdown caret
	.navbar-nav .caret {
		display:none;
 	}

    //  show dropdown menu
 	.dropdown:hover .dropdown-menu {
	   display: block;
  	}

	.dropdown-menu {
		left:0;
		right:0;
  		text-align: center;
  	}	
}

@media (min-width: 769px) and (max-width: 1024px) { 
	.nav {
		font-size: 0.95em
	}	

	.nav > li > a {
		padding-left: 6px;
		padding-right: 6px;
	}

	.navbar-nav > li > a {
	    padding-top: 15px;
	}		

 	.navbar-brand{
		height: $navbar-height-md;
	}	

	//  hide dropdown caret
	.navbar-nav .caret {
		display:none;
 	}

    //  show dropdown menu
 	.dropdown:hover .dropdown-menu {
	   display: block;
  	}

	.dropdown-menu {
		left:0;
		right:0;
  		text-align: center;
  	}	
}

@media (min-width: 1025px)  {  
	.nav {
		font-size: 1.3em;
	}	

	.nav > li > a {
		padding-left: 6px;
		padding-right: 6px;
	}	

	.navbar-nav > li > a {
	    padding-top: 20px;
	}		

 	.navbar-brand{
		height: $navbar-height-lg;
	}	

	//  hide dropdown caret
	.navbar-nav .caret {
		display:none;
 	}

    //  show dropdown menu
 	.dropdown:hover .dropdown-menu {
	   display: block;
  	}

	.dropdown-menu {
		left:0;
		right:0;
  		text-align: center;
  	}

	// .navbar-nav#main-menu-nav > li > a:after {
	//     content: "|";
	//     margin-left: 5px;
	// }	
}

