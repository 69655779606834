//  navbar-bottom{
.navbar-container{
    
}

.nav-bottom {
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
}

.nav-bottom > li > a {
    padding: 5px 5px;
}

.nav-bottom > li, .nav-bottom > li > a {
    display: block;
    position: relative;
}

// .navbar-bottom-nav > ul:after { content: 'Our Centres:\00a0'; color: $footer-address-type-colour; }

.navbar-bottom-nav > li > a {
    float: left;
    color: $white;
    text-decoration: none;

      @media (max-width: 567px) {
        padding-bottom: 2px;
        padding-top: 2px;
      } 

      @media (min-width: 568px) {
        padding-bottom: 5px;
        padding-top: 5px;
      }

      @media (min-width: 667px) and (max-width: 767px) { 
        padding-bottom: 5px;
        padding-top: 5px; 
      }

      @media (min-width: 766px) and (max-width: 1024px) { 
        padding-bottom: 10px;
        padding-top: 10px;   
      }

      @media (min-width: 1025px)  {  
        padding-bottom: 10px;
        padding-top: 10px; 
      }

}

.navbar-bottom-nav > li > a:after {
    content: "|";
    margin-left: 7px;
}
