@mixin button-font-breakpoints{

	@media (min-width: 568px) {
		font-size: 1.0em;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
		font-size: 1.05em;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		font-size: 1.15em;
  	}

	@media (min-width: 1025px)  {  
		font-size: 1.2em;
	} 

}

@mixin button-size-px-breakpoints{
	@media (min-width: 568px) {
		width: 125px;
		height:30px;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
	  	width: 100px;
	  	height:35px;
		padding-top:0.75em;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		width: 150px;
		height:45px;
		padding-top:0.75em;
  	}

	@media (min-width: 1025px)  {  
		width: 180px;
		height:50px;
	}
}

@mixin button-size-pc-breakpoints{

	@media (min-width: 568px) {
 		height: $button-height-sm;
		width: 30%;
		left:35%;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
 		height: $button-height-md;
		width: 30%;
		left:35%;	  	
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
 		height: $button-height-md;
		width: 30%;
		left:35%;
  	}

	@media (min-width: 1025px)  {  
 		height: $button-height-lg;
		width: 30%;
		left:35%;
	} 

}

@mixin button-size-facebook{
	@media (min-width: 568px) {
		width: 30px;
		height:30px;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
	  	width: 35px;
	  	height:35px;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		width: 45px;
		height:45px;
  	}

	@media (min-width: 1025px)  {  
		width: 50px;
		height:50px;
	}
}

@mixin action-button-mixin($background-colour, $text-colour, $bottom-border-colour) {	
	margin: 0 auto;
	font-family: $opensans;
	background: $background-colour;
	color: $text-colour;
	font-size: 1.0em;
	font-weight: 500;
    border-style: solid;
    border-width: 0px 0px 10px 0px;
    border-color: $bottom-border-colour;
    width: 125px;
	height: $button-height;
	text-align: center;
	padding-top: 0.4em;
	z-index: 500;

	//@include vertical-align-centre();

	&:hover {
		cursor: pointer;
		background: $bottom-border-colour;
	}

	a{
		text-decoration: none;
		color: $text-colour;
	}
    	
}

@mixin action-button-disabled-mixin() {
  	-webkit-user-select: none;
  	pointer-events: none;
  	cursor: not-allowed;
  	opacity: .4;  
}

@mixin center-footer-button-mixin($background-colour, $text-colour, $bottom-border-colour) {
	margin: 0 auto;
	font-family: $opensans;
	background: $background-colour;
	color: $text-colour;
	font-size: 1.0em;
	font-weight: 500;
    border-style: solid;
    border-width: 0px 0px 10px 0px;
    border-color: $bottom-border-colour;
	height: 50px;
	width: 180px;
	text-align: center;
	padding-top: 0.5em;
	z-index: 500;

	&:hover {
		cursor: pointer;
		background: $bottom-border-colour;
	}

	a{
		text-decoration: none;
		color: $text-colour;
	}

 	@media (min-width:40em) { 
		font-size: 1.2em;
	}

 	@media (min-width:60em) { 
		font-size: 1.4em;
	}

 	@media (min-width:80em) { 
		font-size: 1.5em;
	}
}

@mixin centre-header-nav-button-mixin($background-colour, $text-colour, $bottom-border-colour) {
	margin: 0 auto;
	font-family: $opensans;
	background: $background-colour;
	color: $text-colour;
	font-size: 0.65em;
	font-weight: 400;
    border-style: solid;
    border-width: 0px 0px 7px 0px;
    border-color: $bottom-border-colour;
	height: 50px;
	width: 180px;
	text-align: center;
	padding-top: 0.5em;
	z-index: 500;

	&:hover {
		cursor: pointer;
		background: $bottom-border-colour;
	}

	a{
		text-decoration: none;
		color: $text-colour;
	}

 	@media (min-width:40em) { 
		font-size: 0.8em;
	}

 	@media (min-width:60em) { 
		font-size: 1.0em;
	}

 	@media (min-width:80em) { 
		font-size: 1.1em;
	}	
}

@mixin half-box-button() {
	position:absolute;
  	width:30%;
  	left:35%;
  	bottom: 50px;
}

@mixin third-box-button() {
	position:absolute;
  	width:40%;
  	left:30%;
  	bottom: 50px;
}

.green-button{
	@include action-button-mixin($green-button-background, $white, $green-button-border);
	@include vertical-align-bottom();
	@include button-font-breakpoints();
	@include button-size-pc-breakpoints();
}

.green-button-sidebar{
	@include action-button-mixin($green-button-background, $white, $green-button-border);
	@include button-font-breakpoints();
	width:70%;
}

.green-button-contactus{
	@include action-button-mixin($green-button-background, $white, $green-button-border);
	@include button-font-breakpoints();
	width:180px;
}

.aqua-button{
	@include action-button-mixin($aqua-button-background, $aqua-button-text, $aqua-button-border);
	@include button-font-breakpoints();
	@include button-size-pc-breakpoints();
}

.aqua-button-custom{
	@include action-button-mixin($aqua-button-background, $aqua-button-text, $aqua-button-border);
	@include button-font-breakpoints();
	@include button-size-px-breakpoints();
}

.aqua-button-bottom{
	@include action-button-mixin($aqua-button-background, $aqua-button-text, $aqua-button-border);
	@include vertical-align-custom(80%);
}

.aqua-button-contactus{
	@include action-button-mixin($aqua-button-background, $aqua-button-text, $aqua-button-border);
}

.brown-button{
	@include action-button-mixin($brown-button-background, $brown-button-text, $brown-button-border);
	@include button-font-breakpoints();
	@include button-size-pc-breakpoints();
}

.brown-button-custom{
	@include action-button-mixin($brown-button-background, $brown-button-text, $brown-button-border);
	@include vertical-align-custom(80%);
	@include button-font-breakpoints();
	@include button-size-pc-breakpoints();
}

.brown-button-contactus{
	@include action-button-mixin($brown-button-background, $brown-button-text, $brown-button-border);
	@include button-font-breakpoints();
	width: 180px;
}

.brown-button-contactus.disabled, .brown-button-contactus.disabled:hover {
  	@include action-button-disabled-mixin();
}

.red-button{	
	@include action-button-mixin($red-button-background, $white, $red-button-border);
}

.brown-button-third-box{
	@include action-button-mixin($brown-button-background, $brown-button-text, $brown-button-border);
	@include third-box-button();	
	@include button-font-breakpoints();	
}

.brown-button-half-box{
	@include action-button-mixin($brown-button-background, $brown-button-text, $brown-button-border);
	@include half-box-button();	
	@include button-font-breakpoints();
}

.aqua-button-third-box{
	@include action-button-mixin($aqua-button-background, $aqua-button-text, $aqua-button-border);
	@include third-box-button();	
	@include button-font-breakpoints();
}

.aqua-button-half-box{
	@include action-button-mixin($aqua-button-background, $aqua-button-text, $aqua-button-border);
	@include half-box-button();	
	@include button-font-breakpoints();
}

.centre-news-button{
	@include action-button-mixin($green-button-background, $white, $green-button-background);
	@include button-font-breakpoints();
	width: 90%;
	margin: auto 0;
    border-width: 1px 0px 0px 0px;
	border-color: $white;	
	font-weight:800;

	&:hover {
		background: $centre-news-item-hover-colour;
	}	

}

#view-centre-button{
	padding-bottom: 20px;
}

.home-header-nav-button{
	@include centre-header-nav-button-mixin($green-button-background, $white, $green-button-border);	
	float:left;

	@media (min-width: 568px) {
		margin: -5px auto 0;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
		margin: -15px auto 0;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		margin: -25px auto 0;
  	}

	@media (min-width: 1025px)  {  
		margin: -30px auto 0;
	} 	

	@include button-size-px-breakpoints();
}

.home-header-fb-button{	

	&:hover {
		cursor: pointer;
	}

	@media (min-width: 568px) {
		margin: -5px auto 0;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
		margin: -5px auto 0;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		margin: -10px auto 0;
  	}

	@media (min-width: 1025px)  {  
		margin: -10px auto 0;
	} 	

	@include button-size-facebook();
}

.centre-header-nav-button{
	@include centre-header-nav-button-mixin($green-button-background, $white, $green-button-border);
	
	float:left;

	@media (min-width: 568px) {
		margin: -10px auto 0;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
		margin: -5px auto 0;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		margin: -10px auto 0;
  	}

	@media (min-width: 1025px)  {  
		margin: -10px auto 0;
	} 	

	@include button-size-px-breakpoints();
}

.centre-header-fb-button{
	&:hover {
		cursor: pointer;
	}

	@media (min-width: 568px) {
		margin: -10px auto 0;
	}

	@media (min-width: 667px) and (max-width: 768px) { 
		margin: -5px auto 0;
  	}

  	@media (min-width: 769px) and (max-width: 1024px) { 
		margin: -10px auto 0;
  	}

	@media (min-width: 1025px)  {  
		margin: -10px auto 0;
	} 	

	@include button-size-facebook();
}

#centre-older-news{
	left:5%;
}

.hamburger-menu-button{
	text-transform: uppercase;
	font-weight:600;
	font-family:$opensans;
	color: $brand-blue;
	margin-bottom: 20px;
	border: none;
	background: $page-background-colour;
}

button {
	top:0;
    .button-label {
        display: table-cell;
        vertical-align: middle;
        font-weight: 800;
        padding-right: 3px;
    }

    .button-hamburger {
        display: table-cell;
        padding: 8px;

        .icon-bar {
            background: $brand-blue;;
        }
    }
}
