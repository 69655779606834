// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

// gutters for grid system
// 40px gutter
.gutter-40.row {
  margin: -20px;
}
.gutter-40 > [class^="col-"], .gutter-20 > [class^=" col-"] {
  padding: 20px;
}
// 30px gutter
.gutter-30.row {
  margin: -15px;
}
.gutter-30 > [class^="col-"], .gutter-20 > [class^=" col-"] {
  padding: 15px;
}
// 20px gutter
.gutter-20.row {
  margin: -10px;
}
.gutter-20 > [class^="col-"], .gutter-20 > [class^=" col-"] {
  padding: 10px;
}
// 10px gutter
.gutter-10.row {
  margin: -5px;
}
.gutter-10 > [class^="col-"], .gutter-10 > [class^=" col-"] {
  padding: 5px;
}
// 0px gutter
.gutter-0.row {
  margin: 0;
}
.gutter-0 > [class^="col-"], .gutter-0 > [class^=" col-"]{
  padding: 0;
}

//
@mixin centre-side-bar-box($margin-left: -15px){
  font-size: 1.75em;  
  margin-bottom: 20px;

    a {
      color: $white;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }

    @media (min-width:40em) { 
        padding-bottom:30px;
        margin-left: $margin-left;        
    }

    @media (min-width:60em) { 
        padding-bottom:30px;
        margin-left: $margin-left;
    }

    @media (min-width:80em) { 
        padding-bottom:30px;
        margin-left: $margin-left;
    }  
}

//  content boxes
@mixin grid-content-box-mixin($box-height: auto) {
  height:$box-height;
  
  color: $white;
  text-align: center;
  font-family: $opensans;

  padding-left:10px;
  padding-right:10px;

  margin-bottom:15px;

  h2{
    font-weight: 800;
    text-align: center;
  }

  h3{
    font-weight: 800;
    text-align: left;
    margin-left:5%;
  }

  h4{
      padding: 20px;
  }

  .content-box-title{
    font-weight:800;
    text-align: center;
    font-size:1.5em;
    padding-top:20px;
  }

  .content-box-text{
    padding: 15px;
    text-align: center;
    font-size:1.0em;
  }

  //  Bootstrap dropdown / select
  .btn-default{
    font-size: 1.25em;
    border-radius: 0;
    color: $brand-blue;
    background-color: $white;
    width: 100%;
    border-style: solid;
    border-width: 0px 0px 10px 0px;
    border-color: $brand-aqua;         
  }

  .dropdown-menu{
      background: $dropdown-bg-colour;
      border-radius: 0px;
      text-align: left;
      padding: 10px;
      font-size: 1.05em;
  }

  .dropdown-menu > li > a {
      color: $white;
      background-color: $dropdown-bg-colour;
      text-align: left;
      padding: 10px;

        &:focus{
          background: $dropdown-bg-colour; 
        }

        &:hover{
          background: $dropdown-bg-colour; 
        }
  }

  // border beneath all menu items except for last
  .dropdown-menu > li:not(:last-child) a { 
      border-bottom: solid $white 1px; 
  } 

  //  dropdown width
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 90%;
  }

    @media (max-width: 567px) {

    } 

    @media (min-width:568px) { 
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.25em;
      }        
    }

    @media (min-width: 667px) and (max-width: 767px) { 
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.25em;
      }
    }

    @media (min-width: 766px) and (max-width: 1024px) { 
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.15em;
      }   
    }

    @media (min-width: 1025px)  {  
      .content-box-title{
        font-size:1.8em;
        padding-top:25px;
      }

      .content-box-text{
        padding: 15px;
        font-size:1.25em;
      } 
    }
 
}

@mixin home-content-box-heights{
  @media (max-width: 567px) {
    height:auto;     
    padding-bottom:80px; 
  } 

  @media (min-width: 568px) {
    height:auto;     
    padding-bottom:80px; 
  }

  @media (min-width: 667px) and (max-width: 767px) { 
    height:auto; 
  }

  @media (min-width: 766px) and (max-width: 1024px) { 
    height:350px;    
  }

  @media (min-width: 1025px)  {  
    height:350px;  
  }
}

@mixin team-member-box-heights{
  @media (max-width: 567px) {
    height:auto;     
  } 

  @media (min-width: 568px) {
    height:auto;     
  }

  @media (min-width: 667px) and (max-width: 767px) { 
    height:750px;
  }

  @media (min-width: 766px) and (max-width: 1024px) { 
    height:775px;    
  }

  @media (min-width: 1025px)  {  
    height:775px;  
  }
}

//  sidebar boxes
.sidebar-box-blue{
    background-color: $brand-blue; 
}

.sidebar-box-green{
    background-color: $brand-green; 
      padding-left:10px;
  padding-right:10px;
}

.sidebar-box-blue, .sidebar-box-green{
  @include grid-content-box-mixin();
  @include centre-side-bar-box();

  padding-bottom:20px;

  margin-left:-15px;
  margin-right:-15px;

  @media (min-width: 568px) {
      margin-right:10px;
  }

  @media (min-width: 667px) and (max-width: 768px) { 
       margin-right: 0
       px;
    }

  @media (min-width: 769px) and (max-width: 1024px) { 
      margin-right:15px;
  }

  @media (min-width: 1025px)  {  
      margin-right:15px;
  }

}