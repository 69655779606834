    // additional styles added by Candice - these were inline in head.php but have moved to seperate scss for consistency / ease of maintenance
    .listCentreFooter h3 {
        font-size: 18px;
        font-weight: 800;
        background: url('/wp-content/themes/educare/assets/images/footer-title-bkg.png');
        background-size: auto 41px;
        background-repeat: no-repeat;
        color: #1279bd;
        margin-top: 35px;
        padding-top: 14px;
        padding-left: 70px;
        padding-bottom: 8px;
        margin-left: -70px;
        margin-bottom: 11px;
        display: inline-block;
        min-width: 357px;
    }

    .listCentreFooter p, .footer-email a, .footer-phone {
        font-weight: 300;
        line-height: 22px;
    }

    .footer-phone:before {
        font-weight: 600;
    }

    .privacyPolicyLink {
        color: #fff;
        text-decoration: underline;
    }

    .socialLinks {
        margin-bottom: 50px;
    }

    .socialLinks a {
        color: #fbb731;
        font-size: 16px;
    }

    .listCentreFooter h4 {
        color: #fbb731;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
    }

    .centreList {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        width: 105px;
        margin-bottom: 25px;
    }

    .centreCol {
        margin-right: 75px;
        display: block;
        float: left;
    }

    .centreCol:last-of-type {
        margin-right: 0;
    }

    .centreList a {
        color: #fff;
        font-weight: 300;
        line-height: 22px;
    }

    .pre-enrolment {
        background: #f4e5ce;
        padding-top: 25px;
    }

    .pre-enrolment h1 {
        font-weight: 800;
        color: #67a41f;
        text-align: center;
        margin-bottom: 10px;
        padding: 0 15px;
    }

    .pre-enrolment h3 {
        text-align: center;
        color: #67a41f;
        margin-top: 10px;
        padding: 0 15px;
    }

    #formWidget .container {
        max-width: 100%;
    }

    #formWidget .discover.discover-enquiry-form {
        width: 90%;
        background: none;
        border: none;
        border-top: 1px solid #67a41f;
        border-radius: 0;
        padding-top: 40px;
        margin-top: 30px;
        box-shadow: none;
        font-family: Open Sans;
    }

    .page-template-full-enrolment-2019 .discover {
        width: 90%;
        background: none;
        border: none;
        border-top: 1px solid #67a41f;
        border-radius: 0;
        padding-top: 40px;
        margin-top: 30px;
        box-shadow: none;
        font-family: Open Sans;
    }

    #formWidget h2 {
        display: none;
    }

    #formWidget label {
        color: #0077c0;
        font-weight: 600;
        line-height: 18px;
        font-size: 14px;
    }

    #formWidget button.discover-close {
        color: #fff;
        text-shadow: none;
        opacity: 1;
    }

    #formWidget .secondary-steps button.discover-close {
        color: #0077c0;
    }

    #formWidget button.discover-close:hover {
        opacity: 0.6;
    }

    #formWidget input, #formWidget textarea, #formWidget select, #formWidget .discover-chosen-choices {
        border-radius: 0;
        border: 1px solid #0077c0;
        box-shadow: none;
        font-family: Open Sans;
    }

    #formWidget .WidgetMinDays {
        margin-right: 10px;
    }

    #formWidget .discover-chosen-container-multi .discover-chosen-choices li.search-field input[type="text"] {
        margin: 0;
        height: 26px;
    }

    .discover-chosen-container .discover-chosen-results li {
        text-align: left;
    }

    #formWidget .discover-form-group > .col-lg-3.col-md-3 {
        width: 10%;
    }

    #formWidget .discover-form-group .col-lg-1 {
        width: 23.33333333%;
    }

    #formWidget .discover-check-box {
        text-align: right;
    }

    #formWidget .discover-enquiry-form .discover-align-checkbox-label {
        font-size: 14px;
    }

    #formWidget .discover-check-box input[type="checkbox"]~label {
        margin-right: 0;
    }

    #formWidget .discover-btn, 
    #formWidget .wizard>.actions a,
    #formWidget .discover-alert-dialog button {
        border-radius: 0;
        border: 0;
        padding: 10px 40px 6px 40px;
        margin-left: 5px;
    }

    #formWidget .discover-btn-info:hover,
    #formWidget .discover-btn.discover-btn-danger:hover,
    #formWidget .discover-row .discover-btn-info-light-blue:hover {
        background: #ed9427;
        border-bottom-color: #be761f;
    }

    #formWidget .wizard>.actions .disabled a, 
    #formWidget .wizard>.actions .disabled a:hover, 
    #formWidget .wizard>.actions .disabled a:active,
    #formWidget .discover-alert-dialog button.discover-alert-btn-no,
    #formWidget .discover-btn.discover-doctor-cancel-btn {
        background: #d8d8d8;
        border-bottom: 10px solid #c0c0c0;
    }

    #formWidget .discover-alert-dialog button.discover-alert-btn-no:hover,
    #formWidget .discover-btn.discover-doctor-cancel-btn:hover {
        background: #c0c0c0;
        border-bottom: 10px solid #b5b5b5;
    }


    #formWidget .discover-btn-info,
    #formWidget .discover-btn.discover-btn-danger,
    #formWidget .discover-row .discover-btn-info-light-blue {
        background: #fbb731;
        border-bottom: 10px solid #ed9427;
    }

    #formWidget .discover-btn-primary,
    #formWidget .wizard>.actions a,
    #formWidget .discover-alert-dialog button.discover-alert-btn-yes,
    #formWidget .discover-btn.discover-start-again-btn,
    #formWidget .discover-btn.discover-register-yes-btn,
    #formWidget .discover-btn#btnCreateDoctor {
        background: #67a41f;
        border-bottom: 10px solid #3d7212;
    }

    #formWidget .discover-btn-primary:hover,
    #formWidget .wizard>.actions a:hover,
    #formWidget .wizard>.actions a:active,
    #formWidget .discover-alert-dialog button.discover-alert-btn-yes:hover,
    #formWidget .discover-btn.discover-start-again-btn:hover,
    #formWidget .discover-btn.discover-register-yes-btn:hover,
    #formWidget .discover-btn#btnCreateDoctor:hover {
        background: #3d7212;
        border-bottom-color: #284b0c;
    }

    #formWidget .discover-enquiry-form .discover-field-validation-error {
        font-size: 12px;
    }


    #formWidget .discover-form-group select.discover-form-control, #formWidget .discover-chosen-choices {
        min-height: 38px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-border-radius: 0px;
        background-image: url("https://image.flaticon.com/icons/svg/60/60781.svg");
        background-position: top 11px right 10px;
        background-repeat: no-repeat;
        background-size: 14px;
        padding-right: 1.5em;
        min-width: 120px;
    }

    #formWidget .discover-form-group select.discover-form-control#DoctorID {
        min-width: 270px;
    }

    #formWidget .discover-chosen-choices {
        padding: 4px;
    }

    .page-template-enrolment-2019 .datepicker-dropdown, .page-template-full-enrolment-2019 .datepicker-dropdown {
        max-width: 250px;
    }

    #formWidget .discover-form-group .discover-form-control, #formWidget .discover-form-group .discover-form-control-required, #formWidget .address-input-text-box input {
        padding: 8px 12px;
        height: auto;
    }

    #formWidget .discover-panel-heading {
        background: #0077c0;
        border-radius: 0;
        color: #ffffff;
        border: none;
            
    }

    #formWidget .discover-child-details {
        margin-top: 30px;
    }

    #formWidget .discover-panel {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
    }

    #formWidget .input-group-addon {
        color: #0077c0;
        border-radius: 0;
        border-color: #0077c0;
    }

    .wizard.primary-steps>.steps, .wizard.primary-steps>.actions {
        background: #f4e5ce;
        padding-top: 10px;
    }

    #formWidget .wizard>.actions a, 
    #formWidget .wizard>.actions a:hover, 
    #formWidget .wizard>.actions a:active {
        border-radius: 0;
    }


    #formWidget .wizard>.steps .current a, 
    #formWidget .wizard>.steps .current a:hover, 
    #formWidget .wizard>.steps .current a:active {
        background: #0077c0;
        margin: 0 4px 0 0;
        border-radius: 0;
    }

    #formWidget .wizard>.steps .disabled a, 
    #formWidget .wizard>.steps .disabled a:hover, 
    #formWidget .wizard>.steps .disabled a:active {
        margin: 0 4px 0 0;
        border-radius: 0;
        background: #ddd;
        color: #0077c0;
    }

    #formWidget .wizard>.steps .done a, 
    #formWidget .wizard>.steps .done a:hover, 
    #formWidget .wizard>.steps .done a:active {
        background: #0077c0;
        margin: 0 4px 0 0;
        border-radius: 0;
        opacity: 0.5;
        color: #fff;
    }

    #formWidget .discover .wizard>.steps .disabled a span {
        color: #0077c0;
    }

    #formWidget .discover .wizard>.steps .done a span {
        color: #fff;
    }

    .datepicker-dropdown, .discover-alert-dialog, #ShowDialog .discover-modal-body {
        font-family: Open Sans;
    }

    #formWidget .wizard>.content>.body {
        width: 100%;
    }

    #formWidget #profileForm-p-3 .discover-tooltip-box div {
        margin-left: 0;
    }

    #formWidget #profileForm-p-3 .discover-tooltip-box div:after {
        right: auto;
        left: 56px;
    }

    #formWidget .discover-permissions-space .discover-tooltip-box div {
        min-width: 140px;
    }

    .discover-chosen-container {
        width: 100% !important;
    }

    #formWidget .discover-row {
        margin: 0;
    }

    .facebook i {
        color: #0077c0;
        font-size: 20px;
        margin-right: 6px;
    }

    .facebook a {
        color: #0077c0;
    }

    .facebook {
        font-size: 18.2px;
        font-weight: 600;
    }

    @media screen and (max-width: 1199px) {

        #formWidget .discover-form-group .discover-control-label {
                text-align: left;
            }
    }


    @media screen and (min-width: 992px) and (max-width: 1199px) {
        #formWidget .discover-form-group > .col-md-3 {
            width: 33.3333%;
        }

        #formWidget .discover-form-group > .col-md-9 {
            width: 66.6666%;
        }
    }

    @media screen and (max-width: 991px) {
        .centreCol {
            margin-right: 20px;
        }

        .centreCol:last-of-type  {
            margin-right: 0;
        }

        #formWidget .checkbox-label, #formWidget .discover-form-group .col-lg-1 {
            float: left;
            width: 40px;
            padding-right: 0;
        }

        #formWidget .discover-form-group .discover-checkbox-container + .col-lg-8 {
            width: calc(100% - 45px);
        }

        #formWidget .discover-form-group .col-lg-3 {
            display: none;
        }

        #formWidget .discover-permissions-space .discover-tooltip-box div {
            margin-left: 0;
        }

        #formWidget  .discover-tooltip-box.discover-permission-tooltip-left div:after {
            left: 10px;
        }
    }

    @media screen and (max-width: 700px) {
        #formWidget form.wizard.primary-steps>.steps>ul>li,
        #formWidget .wizard > .steps > ul > li {
            width: 100%;
        }

        #formWidget form.wizard.primary-steps>.steps>ul>li a, 
        #formWidget form.wizard.primary-steps>.steps>ul>li a:hover,
        #formWidget form.wizard.primary-steps>.steps>ul>li a:active,
        #formWidget .wizard > .steps > ul > li a,
        #formWidget .wizard > .steps > ul > li a:hover,
        #formWidget .wizard > .steps > ul > li a:active {
            margin-right: 0;
        }

        #formWidget .discover-childhealth-content {
            padding-left: 0;
            padding-right: 0;
        }

        #formWidget .discover-childhealth-content .discover-popup-content {
            padding-left: 0;
            padding-right: 0;
        }

        #formWidget .discover-form-group select.discover-form-control#DoctorID {
            min-width: 0;
        }

        #formWidget .discover-form-group select.discover-form-control#DoctorID {
            width: 100%;
        }

        #formWidget .discover-input-address-box {
            display: block;
        }

        #formWidget .discover-input-address-box .discover-input-group-btn {
            margin-top: 6px;
            top: 0;
            width: 100%;
            display: block;
            padding-left: 0;
        }

        #formWidget .discover-input-address-box .discover-btn {
            margin-left: 0;
        }
    }


    @media screen and (max-width: 525px) {	
        

        #formWidget .discover-btn {
            margin-bottom: 10px;
            width: 100%;
        }

        #formWidget .discover.discover-enquiry-form {
            width: 100%;
        }


    }
