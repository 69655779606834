//ACF Google Map canvas styles

@mixin acf-map-mixin($height: 930px, $margin-top: 20px) {
    width: 100%;
    height: $height;
    margin-top: $margin-top;

 	@media (min-width:40em) { 
    	margin-top: 0; // small-up 
  	}
 	@media (min-width:60em) { 
    	margin-top: 0; // medium-up 
  	}
  	@media (min-width:80em) { 
	    margin-top: $margin-top; // large-up 
  	}
}

.acf-map {
    width: 100%;
	@include acf-map-mixin();
}

.acf-map-small {
    width: 100%;
	@include acf-map-mixin(500px, 0px);
}

.acf-map img {
   max-width: inherit !important;
}

.acf-map-small img {
   max-width: inherit !important;
}

.google-map-info-centre-name{
	color: $brand-primary;
	font-weight: 600;
	font-size: 1.25em;
	padding: 15px;
}

.google-map-info-address{
	color: $brand-primary;
	font-weight: 300;
	font-size: 1.0em;	
}