//  navbar

.nav-centre {    
    margin-bottom: 0;
    padding-left: 0;
    font-size: 0.9em;
    font-weight: 300;
}

.nav-centre#centre-nav-home{
    font-size: 1.0em;
    font-weight: 800;
}

.nav-centre > li > a {
    padding: 5px 5px;
}

.nav-centre > li, .nav-centre > li > a {
    display: block;
    position: relative;
}

.navbar-centre-brand{
    height: 20px;
    padding: 5px;
}

.navbar-centre-nav > li > a {
    float: none;
    color: $brand-blue;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-centre-nav > li > a:hover {    
    color: $brand-green;
    font-weight:600;
}

@media (min-width: 568px) {
    .nav-centre {
        font-size: 1.0em;
    }

    .nav-centre#centre-nav-home{
        font-size: 1.0em;
    }
}

@media (min-width: 667px) and (max-width: 768px) { 
    .nav-centre {
        font-size: 1.0em;
    }

    .nav-centre#centre-nav-home{
        font-size: 1.0em;
    }     

    .navbar-centre-nav > .active > a {
            color: $brand-green;
    }    

    .navbar-centre-nav > li > a {
        padding-left: 5px;
        padding-right: 5px;
        float:left;
    }      

}

@media (min-width: 769px) and (max-width: 1024px) { 
    .nav-centre {
        font-size: 1.1em;
    }
    .nav-centre#centre-nav-home{
        font-size: 1.1em;
    }       

    .navbar-centre-nav > .active > a {
            color: $brand-green;
    }    

    .navbar-centre-nav > li > a {
        padding-left: 5px;
        padding-right: 5px;
        float:left;        
    }    

    .navbar-centre-nav#centre-primary-nav > li > a {
        padding-left: 15px;
        padding-right: 15px;
    }        

}

@media (min-width: 1025px)  {  
    .nav-centre {
        font-size: 1.25em;
    }

    .nav-centre#centre-nav-home{
        font-size: 1.25em;
    }     

    .navbar-centre-nav > .active > a {
            color: $brand-green;
    }

    .navbar-centre-nav > li > a {
        float:left;        
    }        

    .navbar-centre-nav#centre-primary-nav > li > a {
        padding-left: 20px;
        padding-right: 20px;
    }        

}
