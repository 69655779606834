@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800);

// Utilities

@mixin vertical-align-centre($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin vertical-align-bottom($position: relative) {
  position: $position;
  top: 80%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin vertical-align-custom($position-from-top, $position: relative) {
  position: $position;
  top: $position-from-top;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//  customising of placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {@content; color: $brand-text-colour;}
  &:-moz-placeholder           {@content; color: $brand-text-colour;}
  &::-moz-placeholder          {@content; color: $brand-text-colour;}
  &:-ms-input-placeholder      {@content; color: $brand-text-colour;}    
}

// group styles
@mixin group-wrapper-default($margin-top, $margin-right, $margin-bottom, $margin-left, $background-colour: $brand-sand, $height:auto){
  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
  background-color: $background-colour; 
  padding-top:40px;
  height:$height;
}

@mixin group-title-default($colour: $brand-blue) {
  font-family: $opensans;
  text-align: center;
  color: $colour;
  font-weight: 800;
  font-size: 2.75em;
}

@mixin group-subtitle-default($colour: $brand-blue){
  font-family: $opensans;
  text-align: left;
  color: $colour;
  font-weight: 500;
  font-size: 1.8em;
}

@mixin group-text-default($colour: $brand-text-colour){
  font-family: $opensans;
  text-align: left;
  font-weight: 300;
  font-size: 1.0em; 
  margin-right:40px;
  padding-bottom:40px;
  color: $colour;
}

@mixin image-dimension-constraints($maxheight, $maxwidth, $minheight, $minwidth){
  max-height: $maxheight;
  max-width: $maxwidth;
  min-height: $minheight;
  min-width: $minwidth
}