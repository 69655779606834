// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Fonts
$opensans:					'Open Sans' !important;
$opensans-light:			'Open Sans Light';
$font-family-sans-serif: 	$opensans;

// Colors

$white:							#ffffff;
$black:							#000000;
$brand-text-colour: 			#464646;
$brand-second:					$white;
$brand-orange:					#fdb813;
$page-background-colour: 		#fffbe2;
$brand-blue:					#0077c0;
$navbar-bg-colour:				#fffbe2;
$footer-bg-colour:				#362f2d;
$brand-green:					#65a605;
$brand-sand:					#f4e5cd;
$brand-aqua:					#00b4e3;
$brand-centre-home:				#f9f2e5;
$brand-centre-transitions:		#ecd6bf;
$brand-centre-gallery:			#fffbe2;
$brand-brown:					#245c09;
$centre-news-item-hover-colour:	#84b837;
$brand-purple:					#014e84;

// Page
$body-bg: 						$page-background-colour;

// $home-page-main-image-height: (
//   // Extra small screen / phone xl x .33
//   xs: 170px,
//   // Small screen / phone xl x .50
//   sm: 260px,
//   // Medium screen / tablet xl x .75
//   md: 390px,
//   // Large screen / desktop
//   lg: 520px,
//   // Extra large screen / wide desktop
//   xl: 520px
// );

$home-page-main-image-height: 165px;
$home-page-main-image-height-xs-landscape: 250px;
$home-page-main-image-height-sm: 340px;
$home-page-main-image-height-md: 440px;
$home-page-main-image-height-lg: 520px;

$home-page-banner-margin-bottom: 10px;
$generic-page-banner-margin-bottom: 5px;
$centre-page-banner-margin-bottom: 5px;

$generic-page-main-image-height: 140px;
$generic-page-main-image-height-xs-landscape: 220px;
$generic-page-main-image-height-sm: 250px;
$generic-page-main-image-height-md: 340px;
$generic-page-main-image-height-lg: 420px;

$centre-page-main-image-height: 180px;
$centre-page-main-image-height-xs-landscape: 260px;
$centre-page-main-image-height-sm: 340px;
$centre-page-main-image-height-md: 430px;
$centre-page-main-image-height-lg: 520px;

$button-height: 50px;
$button-height-sm: 55px;
$button-height-md: 60px;
$button-height-lg: 65px;

// Navbar
$navbar-default-bg:				$page-background-colour;
$navbar-default-link-active-bg: $page-background-colour;
$navbar-default-link-color:		$brand-blue;
$navbar-default-link-active-color: $brand-green;
$navbar-default-link-hover-color: $brand-green;
$navbar-border-radius: 0;
$navbar-default-border: darken($white, 6.5%);

$navbar-height:	70px;
$navbar-height-sm:	100px;
$navbar-height-md:	145px;
$navbar-height-lg:	155px;

//  Dropdown
$dropdown-bg: 					$navbar-bg-colour;
$dropdown-link-color: 			$brand-blue;
$dropdown-bg-colour:			$brand-aqua;

// Footer
$footer-line-spacing: 	1em;	
$footer-height:			25%;
$footer-padding-top:	2%;
$footer-address-type-colour: $brand-orange;

// Section
$section-proud-font-size: 6.5em;
$section-proud-colour: $brand-blue;;

$section-title-font-size: 5.25em;
$section-title-colour: $brand-blue;;

$section-description-font-size: 1.35em;
$section-description-colour: 	$footer-bg-colour;
$section-description-alt-colour: #41641d;

$section-text-font-size: 1.0em;
$section-text-colour: 	$footer-bg-colour;

$flyover-description-font-size: 1.25em;
$flyover-teaser-font-size: 2.0em;

//  Button colours
$aqua-button-background: 	#ade9f1;
$aqua-button-border:		$brand-aqua;
$aqua-button-text:			$brand-blue;
$brown-button-background: 	#3b7302;
$brown-button-border:		#245c09;
$brown-button-text:			$white;
$green-button-background:	$brand-green;
$green-button-border:		$brand-brown;
$red-button-background:		#f0680e;
$red-button-border:			#e24e25;
$facebook-button-background:#507bbe;
$facebook-button-border:	#003471;
//  header
